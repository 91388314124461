import React, { useState } from "react";
import Axios from 'axios';

import "./Registration.css";
function Registration() {
    const [data, setData] = useState({
        HGSB_PREG_DETAILS_ID: "",
    })

    function registrationhandler(e){
        setData({
            ...data, [e.target.name]: e.target.value
        })
    }

    function submit(e){
        e.preventDefault();
        
    }
    console.log(data)
  return (
    <div className='reg_main'>
      <form>
      <h2 className="text-center">Student Registration</h2>
        <div className="form_cont mt-4">
            <div class="input-container">
            <input
                onChange={(e)=>registrationhandler(e)}
                class="input-field"
                type="text"
                placeholder="HGSB_PREG_DETAILS_ID"
                name="HGSB_PREG_DETAILS_ID"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="ACADEMICYEAR_ID"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="REG_CODE"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FNAME"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="LNAME"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FATHER_FNAME"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FATHER_LNAME"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MOTHER_FNAME"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MOTHER_LNAME"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="DOB"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="GENDER"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="DOR"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="SSTATUS"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="ADDRESS1"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="ADDRESS2"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="COUNTRY"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="STATE"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="CITY"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="DISTRICT"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="PIN"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MOBILE"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="PHONE"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="EMAIL_ID"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="STD_TYPE"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="ADMIN_CLASS"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="APP_STREAM"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="PREMARK"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="PMODE"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="PAMOUNT"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="CUSER"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="CDATE"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="EUSER"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FLAG"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="TFLAG"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="date"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="PMODE"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="TFEE"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="VEHICLE_DETAILS_ID"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="BUS_STOP"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="payment status"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="PHOTO"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="STDCOST"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="ADHAR_NO"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="CATEGORY"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="RELIGION"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="DOB_PLACE"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="DOMICILE"
                name="usrnm"
            />
            </div>
            <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="ADDRESS1"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="ADDRESS2"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="COUNTRY"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="STATE"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="CITY"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="DISTRICT"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="PIN"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="Mint"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="Pint"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FT_FNAME"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FT_LNAME"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FT_OCUP"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FT_EDU"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FT_OEDU"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FT_DESIG"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FT_INCOME"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FT_OFFICE"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FT_ADDRESS"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FT_CNO"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FT_MOBILE"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="FT_EMAIL"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MT_FNAME"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MT_LNAME"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MT_OCUP"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MT_EDU"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MT_OEDU"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MT_DESIG"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MT_INCOME"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MT_OFFICE"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MT_ADDRESS"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MT_CNO"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MT_MOBILE"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="MT_EMAIL"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="HKNOW"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="HKNOW_DETAILS"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="RPCLASS"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="BCERTIFICATE"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="CCERTIFICATE"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="SACERTIFICATE"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="APROOF"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="RPHOTO"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="TC"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="EWS"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="parntimage"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="addrs"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="category"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type="text"
                placeholder="transfer"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="report"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="SPORTS"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="DANCE"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="SINING"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="ARTCRAFT"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="SOTHER"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="PCLASS"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="SNAME"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="NBOARD"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="PYEAR"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="MTYPE"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="OMARK"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="GRADE"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="SPORTS1"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="SPORTS2"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="ECACTIVITY1"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="ECACTIVITY2"
                name="usrnm"
            />
        </div>
        <div class="input-container">
            <input
                class="input-field"
                type=""
                placeholder="RFLEAVING"
                name="usrnm"
            />
        </div>
      </div>
      <div className="d-flex justify-content-center align-content-center mt-4">
            <button className="btn btn-submit f-2 color-white">
            Submit
            </button>
        </div>
      </form>
    </div>
  )
}

export default Registration
