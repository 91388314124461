import React, { useEffect, useState } from "react";
import { Table, Input, DatePicker, Typography, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getStudentTCData } from "../../service/api";
import moment from "moment";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import Footer from "../Footer/Footer";

const keys = [
  "REG_CODE",
  "STD_NAME",
  "STD_FATHER_NAME",
  "STD_MOTHER_NAME",
  "STD_LAST_CLASS",
  "STD_TCNO",
  "STD_TC_ISSUE_DATE",
];

const StudentTCTable = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { RangePicker } = DatePicker;
  const [searchResult, setSearchResult] = useState(null);
  const [searchError, setSearchError] = useState("");

  const fetchStudentTCData = async () => {
    setIsLoading(true);
    const res = await getStudentTCData();
    setRows(res);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchStudentTCData();
  }, []);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isShow, setIsShow] = useState(false);

  const handleClick = () => {
    setIsShow(!isShow);
  };

  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //     confirm();
  //     setSearchText(selectedKeys[0]);
  //     setSearchedColumn(dataIndex);
  // };

  const handleSearch = () => {
    // Find the row with the specified code
    setSearchError("");
    if (!searchValue.trim()) {
      setSearchError("Please enter a code");
      return;
    }
    const result = rows.find((row) =>
      row.REG_CODE.includes(searchValue.toUpperCase())
    );

    if (result) {
      setSearchResult(result);
    } else {
      setSearchError("Student not found");
      setSearchResult(null);
    }
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <RangePicker
          style={{ marginBottom: 8, display: "block" }}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e ? [e] : []);
          }}
          onPressEnter={() => {
            confirm();
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? moment(record[dataIndex]).isBetween(value[0], value[1], "day", "[]")
        : "",
    render: (text) => text,
  });

  const columns = [
    {
      title: "Code",
      dataIndex: "REG_CODE",
      key: "REG_CODE",
    },
    {
      title: "Name",
      dataIndex: "STD_NAME",
      key: "STD_NAME",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.STD_NAME.toLowerCase().localeCompare(b.STD_NAME.toLowerCase()),
    },
    {
      title: "Father Name",
      dataIndex: "STD_FATHER_NAME",
      key: "STD_FATHER_NAME",
    },
    {
      title: "Mother Name",
      dataIndex: "STD_MOTHER_NAME",
      key: "STD_MOTHER_NAME",
    },

    {
      title: "Last Class",
      dataIndex: "STD_LAST_CLASS",
      key: "STD_LAST_CLASS",
    },
    {
      title: "TC Number",
      dataIndex: "STD_TCNO",
      key: "STD_TCNO",
    },
    {
      title: "TC Issue Date",
      key: "STD_TC_ISSUE_DATE",
      dataIndex: "STD_TC_ISSUE_DATE",
      ...getColumnSearchProps("STD_TC_ISSUE_DATE"),
    },
    {
      title: "TC URL",
      key: "STD_TCURL",
      dataIndex: "STD_TCURL",
      render: (_, record) => (
        <Typography.Link href={record.STD_TCURL} target="_blank">
          Link
        </Typography.Link>
      ),
    },
  ];

  const search = (rows) => {
    return rows.filter((item) => {
      return keys.some((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toString().toLowerCase());
      });
    });
  };

  return (
    <div className="home_main">
      <Navbar handleClick={handleClick} />
      {/* <Navbar isScrolled={true} handleClick={handleClick} /> */}
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <div
        style={{
          marginTop: "142px",
          marginLeft: "49px",
          fontSize: "20px",
          fontWeight: "700",
        }}
      >
        STUDENT TC DETAILS
      </div>
      <div>
        <Input
          placeholder="Enter Reg Code"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          style={{
            marginTop: "3%",
            width: "30%",
            marginLeft: "4%",
          }}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={handleSearch}
          style={{
            marginLeft: "3%",
          }}
        >
          Search
        </Button>
        {searchError && (
          <p
            style={{
              color: "red",
              marginLeft: "5%",
              marginTop: "3%",
              fontSize: "25px",
            }}
          >
            {searchError}
          </p>
        )}
        {searchResult && (
          <Table
            columns={columns}
            dataSource={[searchResult]}
            loading={isLoading}
            rowKey="REG_CODE"
            style={{
              marginTop: "4%",
            }}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default StudentTCTable;
